import objPath from 'object-path'
import { buildQuery } from '@cwp/utils'
import { formatPhone, normalizeSpaces } from './_utils.js'
import { fetchJSON } from '../../common/fetch'
import { push } from '../_utils'

function clean (data) {
  const obj = objPath(data.d || {})
  const type = obj.get('facePrflTyp') === 'Individual' ? 'advisor' : 'team'
  const isAdvisor = type === 'advisor'
  const images = {}
  const urls = {}
  const social = {}
  const designations = isAdvisor && obj.get('facePrflDistributor.facePrflDstrDesignations.results', [])
    .map(item => item.desgAcronym)
    .filter(Boolean)
    .join(', ')
  const {
    firstNm,
    lastNm,
    midNm
  } = obj.get('facePrflDistributor')

  // Display name
  const name = (() => {
    return isAdvisor
      ? normalizeSpaces(`${firstNm} ${midNm} ${lastNm}`)
      : obj.get('prflPractice.pracNm') || obj.get('prflNkNm')
  })()

  const title = isAdvisor && obj.get('facePrflDistributor.profTtl')

  // Image
  obj.get('prflImages.results', []).forEach(image => {
    const sizeCode = image.photoSizeCd
      .replace(' image', '')
      .toLowerCase()
    images[sizeCode] = image.photoImgURL
  })

  // Urls
  obj.get('prflSocialMediaURLs.results', []).forEach(item => {
    const { mediaTypCd, URL } = item

    switch (mediaTypCd) {
    case 'siteurl':
    case 'TeamWebsiteUrl':
      urls.site = URL
      break
    case 'referme':
      urls.referme = URL
      break
    case 'FacebooklinkUrl':
    case 'TeamFacebooklinkUrl':
      social.facebook = URL
      break
    case 'linkedinurl':
      social.linkedin = URL
      break
    case 'BiopageUrl':
      urls.site = URL
      break
    }
  })

  return {
    designations,
    email: obj.get('prflEmail.emailAddr'),
    id: obj.get('facePrflDistributor.facePrflDstrId'),
    image: images.large || images.medium || images.small,
    name,
    phone: formatPhone(obj.get('prflTelephone.teleNbr')),
    title,
    type,
    urls,
    social
  }
}

/*
 * {
 *   designations: '',
 *   email: '',
 *   id: '',
 *   image: '',
 *   name: '',
 *   phone: '',
 *   title: '',
 *   type: 'advisor|team',
 *   urls: {
 *     'referme|site': ''
 *   },
 *   social: {
 *     linkedin: '',
 *     facebook: '',
 *     twitter: ''
 *   }
 * }
 */
export default async function (options = {}) {
  const result = {
    data: null,
    errors: []
  }

  if (options.id) {
    try {
      const uri = `/api/faceProfile?${buildQuery({ facePrflId: options.id })}`
      const data = await fetchJSON(uri)
      result.data = clean(data)
    } catch (error) {
      result.errors = push(result.errors, error)
    }
  }

  return result
}
