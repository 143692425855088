import {
  newNode,
  clearNode,
  openZIPSearch,
  loadGalleryData,
  thisComponentStandsAlone,
  validateZIP5KeyEvent,
  validateZIP5PasteEvent
} from '../DynamicAdvisorComponent/utils'

import { newAdvisorItem, newMoreAdvisorsItem } from '../AdvisorGallery/html'

import { getStorageZip, isGeoSupported, findGeoLocation, getZipCodeFromAddress, getUserDataFromStorage } from '../../services/geoCoordinatesLookup'

class AdvisorFinder extends HTMLElement {
  form = this.querySelector('.AdvisorProspect-searchForm')
  search = this.querySelector('.AdvisorProspect-input')
  gallery = document.querySelector('.dac-content')
  finder = document.querySelector('.fallback')
  results = document.querySelector('.dac-content .results')
  heading = document.querySelector('.dac-content .advisors .heading')
  spinner = document.querySelector('.spinner')
  geoLocate = this.querySelector('.geolocate-me')
  geoLocationLoader = this.querySelector('.geolocation-loader')
  geoLocationDenied = this.querySelector('.geolocation-denied')
  isStandalone = thisComponentStandsAlone(this)
  zip = null

  setLocation (loc) {
    if (typeof loc !== 'object' || !('postalCode' in loc)) {
      console.error(`The Advisor Finder location didn't contain a postal code!`)
      return false
    }

    this.zip = loc.postalCode
    this.search.value = loc.postalCode
  }

  selfStart () {
    let zip = getStorageZip() || null
    if (zip) {
      this.setLocation(zip)
    }
  }

  dispatchKeys (evt) {
    if (evt.target === this.search) {
      validateZIP5KeyEvent(evt)
    }
  }

  dispatchPastes (evt) {
    if (evt.target === this.search) {
      validateZIP5PasteEvent(evt)
    }
  }

  dispatchSubmissions (evt) {
    if (evt.target === this.form) {
      evt.preventDefault()
      openZIPSearch(this.search.value)
    }
  }

  setCityState (loc) {
    if (typeof loc !== 'object' || !('city' in loc)) {
      this.gallery.hidden = true
      this.finder.hidden = false
      console.error(`The Advisor Gallery location wasn't a properly-formatted object!`)
      return false
    }

    const displaying = document.createTextNode('Displaying advisors near ')
    const textContent = loc.city.long_name + (loc.stateProvince && loc.stateProvince.abbr ? `, ${loc.stateProvince.abbr}` : '')
    const place = newNode('strong', { textContent })
    clearNode(this.heading)
    this.zip = 'postalCode' in loc ? loc.postalCode : null

    this.heading.appendChild(displaying)
    this.heading.appendChild(place)
  }

  renderAdvisorsList (profiles) {
    if (!Array.isArray(profiles)) {
      this.gallery.hidden = true
      this.finder.hidden = false
      console.error(`The Advisor Gallery profiles list wasn't an array!`)
      return false
    }

    const list = newNode('ul', { className: 'u-flex u-sm-flexCol' })
    const previous = this.results.firstElementChild
    profiles.forEach(profile => list.appendChild(newAdvisorItem(profile)))
    list.appendChild(newMoreAdvisorsItem(this.zip))
    this.results.replaceChild(list, previous)
  }

  getGeoLocation (evt) {
    if (evt.target === this.geoLocate) {
      event.preventDefault()
      this.geoLocationLoader.classList.remove('u-hidden')
      if (isGeoSupported()) {
        findGeoLocation((returnObj, message) => {
          if (returnObj && message && message === 'success') {
            const retrievedZip = getZipCodeFromAddress(returnObj)
            retrievedZip.then((response) => {
              if (response.status && response.zip && response.zip.long_name) {
                const zipCode = response.zip.long_name
                this.search.value = zipCode
                let userData = getUserDataFromStorage()
                const locData = { 'postalCode': zipCode }
                userData = { ...userData, ...{ stateProvince: { abbr: userData.state.short_name } }, ...locData }
                this.geoLocationDenied.classList.add('u-hidden')
                this.geoLocationLoader.classList.add('u-hidden')
                this.finder.hidden = true
                this.spinner.hidden = false
                loadGalleryData(zipCode)
                  .then((profiles) => {
                    this.setCityState(userData)
                    this.renderAdvisorsList(profiles)
                    this.gallery.hidden = false
                    this.finder.hidden = true
                    this.spinner.hidden = true
                  }).catch(() => {
                    this.gallery.hidden = true
                    this.finder.hidden = false
                    this.spinner.hidden = true
                  })
              }
            }).catch(() => {
              this.geoLocationLoader.classList.add('u-hidden')
              this.geoLocationDenied.classList.remove('u-hidden')
              this.spinner.hidden = true
              this.finder.hidden = false
              this.gallery.hidden = true
            })
          } else {
            this.geoLocationLoader.classList.add('u-hidden')
            this.geoLocationDenied.classList.remove('u-hidden')
            this.gallery.hidden = true
            this.finder.hidden = false
            this.spinner.hidden = true
          }
        })
      }
    }
  }

  connectedCallback () {
    this.addEventListener('keydown', this.dispatchKeys)
    this.addEventListener('paste', this.dispatchPastes)
    this.addEventListener('submit', this.dispatchSubmissions)
    this.addEventListener('click', this.getGeoLocation)

    if (this.isStandalone) {
      this.selfStart()
    }
  }
}

customElements.define('advisor-finder', AdvisorFinder)
