import objPath from 'object-path'
import { buildQuery } from '@cwp/utils'
import { formatPhone } from './_utils.js'
import { fetchJSON } from '../../common/fetch'

function clean (data) {
  return objPath.get(data, 'result.advisorlist', [])
    .map(advisor => {
      const obj = objPath(advisor)
      const type = obj.get('recordType') === 'Advisor' ? 'advisor' : 'team'
      const title = type === 'advisor' ? obj.get('proffessionaltitle') : obj.get('teamtier')
      const designations = (obj.get('professionaldesignations') || '')
        .split(', ')
        .map(html => {
          const tempElement = document.createElement('div')
          tempElement.innerHTML = html
          return tempElement.textContent
        })
        .join(', ')
      const images = ['profilelargeimage', 'profilesmallimage', 'profileteamimage']
        .reduce((result, key) => {
          const value = obj.get(key)

          if (value) {
            const newKey = key.replace(/image|profile/g, '')
            result[newKey] = value
          }

          return result
        }, {})
      const urls = ['referMeUrl', 'siteUrl']
        .reduce((result, key) => {
          const value = obj.get(key)

          if (value) {
            const newKey = key.replace('Url', '').toLowerCase()
            result[newKey] = value
          }

          return result
        }, {})

      return {
        designations,
        email: obj.get('email'),
        id: obj.get('ameripriseid'),
        image: images.large || images.small,
        name: obj.get('displayname'),
        phone: formatPhone(obj.get('phonenumber')),
        title,
        type,
        urls
      }
    })
}

/*
 * [
 *   {
 *     designations: '',
 *     email: '',
 *     id: '',
 *     image: '',
 *     name: '',
 *     phone: '',
 *     title: '',
 *     type: 'advisor|team',
 *     urls: {
 *       'referme|site': ''
 *     }
 *   }
 * ]
 */
export default async function (options = {}) {
  let result

  if (options.zip) {
    const { zip } = options
    const query = buildQuery({ numberToReturn: 4, startRowIndex: 0, zip })
    const uri = `/api/advisorsv22/json/GetSearchByZipRandom?${query}`
    const data = await fetchJSON(uri).then(clean)

    // TODO: handle errors
    result = { data, errors: [] }
  }

  return result
}
