import xss from 'xss'

// Tab component
class TabComponent extends HTMLElement {
  connectedCallback () {
    this.hideAll()

    /*
     * iFrame resizing
     * If this message is in 'something=number' format
     * then use number as the height
     * of the iframe this message came from.
     */
    window.addEventListener('message', evt => {
      const kv = typeof evt.data === 'string' ? evt.data.split('=') : []
      const height = kv.length === 2 && !isNaN(kv[1]) ? parseInt(kv[1], 10) : false
      if (height && height > 0) {
        const iframe = this.iframes.find(i => i.contentWindow === evt.source)
        iframe.height = height
      }
    })

    /* service-based tabs */
    this.querySelectorAll('.sbt-placeholder').forEach(sbt => {
      const iframe = Object.assign(document.createElement('iframe'), sbt.dataset)
      iframe.src = iframe.authUrl || iframe.contentUrl

      iframe.addEventListener('load', function () {
        if (this.authUrl && this.authUrl === this.src) {
          this.src = this.contentUrl + '#' + encodeURIComponent(document.location.href)
        }
      })

      sbt.parentNode.replaceChild(iframe, sbt)
      this.iframes.push(iframe)
    })

    const tab = this.querySelector('tab-inner')
    if (tab) {
      if (this.checkHash()) {
        this.show(this.checkHash())
      } else {
        this.show(tab.getAttribute('id'))
      }
    }

    this.addEventListener('click', e => {
      let idx
      if (e.target.classList.contains('Button-icon')) {
        idx = e.target.parentNode && e.target.parentNode.getAttribute('aria-controls')
      } else {
        idx = e.target.getAttribute('aria-controls')
      }
      if (!idx) { return }

      e.preventDefault()
      this.hideAll()
      this.show(idx)
    })

    this.wrapContent()
  }

  wrapContent () {
    this.querySelectorAll('tab-inner').forEach(tab => {
      let blocks = tab.querySelectorAll('.content-block')
      let container = tab.querySelector('.Tab-content').classList

      if (container.contains('img-right') || container.contains('img-left')) {
        blocks.forEach(block => {
          let images = block.querySelectorAll('img')
          images.forEach(image => {
            if (image) {
              image.classList.add('u-sizeFull')

              // Wrap content
              let wrapper = document.createElement('div')
              wrapper.innerHTML = xss(block.innerHTML)
              block.innerHTML = ''
              block.className += ' has-image u-flex u-sm-flexCol'
              wrapper.className = 'Tab-text u-md-size2of3 u-lg-size2of3 u-paddingVert2gu'
              if (container.contains('img-left')) {
                wrapper.classList.add('u-flexOrderLast')
              }
              var child = wrapper.querySelector('img')
              child.parentNode.removeChild(child)
              block.appendChild(wrapper)

              // Wrap images
              let figure = document.createElement('div')
              figure.className = 'Tab-image u-sm-sizeFull u-md-size1of3 u-lg-size1of3'
              if (container.contains('img-left')) {
                figure.classList.add('u-marginRight4gu')
                figure.classList.add('u-sm-marginRight0')
              }
              if (container.contains('img-right')) {
                figure.classList.add('u-marginLeft4gu')
                figure.classList.add('u-sm-marginLeft0')
              }
              images.forEach(image => figure.appendChild(image))
              block.appendChild(figure)
            }
          })
        })
      }
    })
  }

  checkHash () {
    if (window.location.hash) {
      const hash = window.location.hash.substring(1)
      const button = this.querySelector(`button[id="${hash}"]`)
      if (button && button.hasAttribute('tab-idx')) {
        return button.getAttribute('tab-idx')
      }
    }
  }

  hideAll () {
    if (document.querySelector('.Tabs.vertical')) {
      this.querySelectorAll('[role="tab"]').forEach(button => {
        button.setAttribute('aria-selected', 'false')
        button.classList.remove('u-textColorBrandLight')
        button.classList.add('u-textColorDefault')
      })
    } else {
      this.querySelectorAll('[role="tab"]').forEach(button => {
        button.setAttribute('aria-selected', 'false')
        button.classList.remove('Item-active')
        button.classList.remove('u-textColorBrandLight')
        button.classList.add('u-textColorDefault')
      })
    }

    this.querySelectorAll('tab-inner').forEach(tab => {
      tab.hidden = true
      tab.setAttribute('aria-expanded', 'false')
    })
  }

  show (idx) {
    const selector = `tab-inner[id="${idx}"] , tab-inner[id="${idx.replace(/-a$/, '-b')}"]`
    let tabs = this.querySelectorAll(selector)
    let button = this.querySelector(`button[aria-controls="${idx}"]`)

    if (document.querySelector('.Tabs.vertical')) {
      button.classList.remove('u-textColorDefault')
      button.classList.add('u-textColorBrandLight')
    } else {
      button.classList.remove('u-textColorDefault')
      button.classList.add('Item-active')
      button.classList.add('u-textColorBrandLight')
    }
    button.setAttribute('aria-selected', 'true')

    tabs.forEach(tab => {
      tab.hidden = false
      tab.setAttribute('aria-expanded', 'true')
    })
  }

  iframes = []
}
customElements.define('tab-component', TabComponent)