const display = (nodes, show) => {
  const list = Array.isArray(nodes) ? nodes : [nodes]
  const action = show ? 'remove' : 'add'
  list.forEach(node => node.classList[action]('hide'))
}
const show = nodes => display(nodes, true)
const hide = nodes => display(nodes, false)
const expand = node => node.setAttribute('aria-expanded', 'true')
const collapse = node => node.setAttribute('aria-expanded', 'false')
const selectEachFirst = question => {
  question.querySelector('input').checked = false
}

class AssessComponent extends HTMLElement {
  get isValid () {
    let valid = true
    this.answers.forEach(answer => {
      if (!answer.validate()) {
        valid = false
      }
    })
    return valid
  }

  connectedCallback () {
    const { form, openBtn, reopenBtn, closeBtn, explanation, resultsContainer } = this
    const dispatchClick = evt => {
      const { target } = evt
      if (target.isSameNode(openBtn) || target.isSameNode(reopenBtn)) {
        expand(form)
        show([closeBtn, explanation])
        hide([openBtn, reopenBtn, resultsContainer])
      } else if (target.isSameNode(closeBtn)) {
        collapse(form)
        show(openBtn)
        hide([closeBtn, reopenBtn])
      }
    }

    this.answers.forEach(selectEachFirst)

    form.addEventListener('submit', e => {
      e.preventDefault()
      this.showResults()
      this.answers.forEach(selectEachFirst)
    })
    this.addEventListener('click', dispatchClick)
  }

  calculateScores () {
    let scores = {
      __points: 0
    }

    this.answers.forEach(answer => {
      let answerPoints = answer.points
      // default points
      scores.__points += answerPoints.points
      if (answerPoints.category) {
        if (!scores[answerPoints.category]) {
          scores[answerPoints.category] = 0
        }
        scores[answerPoints.category] += answerPoints.categoryPoints
      }
    })

    return scores
  }

  findResult (scores) {
    // Less than ideal iteration solution because IE11, and for..of isn't properly polyfilled by Babel
    for (let i = 0; i < this.results.length; i++) {
      let result = this.results[i]
      if (result && result.qualifies && result.qualifies(scores)) {
        return result
      }
    }

    return null
  }

  showResults () {
    if (!this.validate()) {
      return
    }

    const scores = this.calculateScores()
    const result = this.findResult(scores)
    if (!result) {
      return
    }

    this.results.forEach(result => result.setAttribute('aria-selected', 'false'))
    result.setAttribute('aria-selected', 'true')
    collapse(this.form)
    show([this.resultsContainer, this.reopenBtn])
    hide([this.closeBtn, this.explanation])
    this.scrollToResult()
  }

  scrollToResult () {
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const position = this.getBoundingClientRect()

    window.scrollTo(scrollLeft, scrollTop + position.top - 10)
  }

  validate () {
    if (!this.isValid) {
      this.classList.add('u-textColorAlert')
      this.error.classList.add('u-block')
      this.error.classList.remove('u-hidden')
      return false
    } else {
      this.classList.remove('u-textColorAlert')
      this.error.classList.remove('u-block')
      this.error.classList.add('u-hidden')
      return true
    }
  }

  openBtn = this.querySelector('.open-btn')
  reopenBtn = this.querySelector('.reopen-btn')
  closeBtn = this.querySelector('.close-btn')
  form = this.querySelector('form')
  answers = this.querySelectorAll('assess-answer')
  explanation = this.querySelector('.explanation')
  resultsContainer = this.querySelector('.results')
  results = this.querySelectorAll('assess-result')
  error = this.querySelector('assess-error')
}
customElements.define('assess-component', AssessComponent)
