import { newNode } from '../DynamicAdvisorComponent/utils'
import { getAdvisorLinkWithCookie } from '../../ui/AdvisorQueryParameter/advisors-query-param'

export const newProfileImage = (json = {}) => {
  json = typeof json === 'object' ? json : {}
  const src = json.image || `${window.AMPCOMPATH}images/dac-missing-image.png`
  const href = json.urls ? getAdvisorLinkWithCookie(json.urls.site) : null
  const alt = href ? 'Visit advisor website' : ''
  const img = newNode('img', { src, alt, className: 'Advisor-image u-maxWidth100' })

  if (href) {
    const target = '_blank'
    const rel = 'noopener noreferrer'
    const link = newNode('a', { href, target, rel, className: 'Link u-maxWidth100' })
    link.append(img)

    return link
  } else {
    return img
  }
}

export const newInfoItems = (json = {}) => {
  json = typeof json === 'object' ? json : {}
  const details = [ 'designations', 'title', 'email', 'phone' ]
  const items = document.createDocumentFragment()
  const className = 'u-marginBottom2gu u-textBreak'
  const newItem = textContent => newNode('p', { textContent, className })
  const newItemContainer = info => {
    switch (info) {
    case 'phone':
      return newNode('a', { href: `tel:${json[info].replace(/\s+/g, '')}` })
    case 'email':
      return newNode('a', { href: `mailto:${json[info]}` })
    default:
      return document.createDocumentFragment()
    }
  }
  details
    .filter(info => typeof json[info] === 'string' && json[info].length)
    .forEach(info => {
      const item = newItemContainer(info)
      const data = newItem(json[info])
      item.append(data)
      items.append(item)
    })

  return items
}

export const newSocialIcons = (social = {}) => {
  social = typeof social === 'object' ? social : {}
  const html = document.createDocumentFragment()
  const services = Object.keys(social)
  const socialIcons = {
    twitter: '&#xea96;',
    facebook: '&#xea91;',
    linkedin: '&#xeac9;'
  }

  if (services.length) {
    const list = newNode('ul', { className: 'u-decoBorderReset u-marginLeft0' })
    const className = 'u-textSizeMsrp1 u-flexJustifyCenter u-inlineBlock u-marginRight6gu'
    const target = '_blank'
    const rel = 'noopener noreferrer'

    services.forEach(svc => {
      const href = social[svc]
      const item = newNode('li', { className })
      const link = newNode('a', { href, target, rel, className: 'Icon-link' })
      const icon = newNode('span', { className: 'Icon u-textSizeMsrp3', innerHTML: socialIcons[svc] })
      const hidden = newNode('span', { className: 'u-hiddenVisually', textContent: svc })

      icon.setAttribute('aria-hidden', 'true')
      list.appendChild(item).appendChild(link).appendChild(icon)
      link.appendChild(hidden)
    })
    html.appendChild(list)
  }

  return html
}
