import { newProfileImage, newInfoItems, newSocialIcons } from './html'
import { newEvent, loadProfileData, thisComponentStandsAlone } from '../DynamicAdvisorComponent/utils'
import { getStorageZip, getUserDataFromStorage } from '../../services/geoCoordinatesLookup'
import { getAdvisorLinkWithCookie } from '../../ui/AdvisorQueryParameter/advisors-query-param'

class AdvisorProfile extends HTMLElement {
  profileID = this.getAttribute('profile-id')
  spinner = this.querySelector('.spinner')
  profile = this.querySelector('.profile')
  fallback = this.querySelector('.fallback')
  finder = this.querySelector('advisor-finder')
  image = this.querySelector('.AdvisorClient-image')
  info = this.querySelector('.AdvisorClient-info')
  link = this.querySelector('.AdvisorClient-link')
  name = this.querySelector('.AdvisorClient-name')
  referral = this.querySelector('.AdvisorClient-refer')
  referAdvisorConent = this.querySelector('.AdvisorClient-refer-content')
  isStandalone = thisComponentStandsAlone(this)
  show (show = true) {
    if (typeof show !== 'boolean') {
      console.error('The Advisor Profile show method received an invalid argument.')
      return false
    }

    this.spinner.hidden = true
    this.profile.hidden = !show
    this.fallback.hidden = show
  }

  renderProfile (data) {
    if (!Object.keys(data).length) {
      this.show(false)
      console.error(`The Advisor Profile argument contained no data!`)
      return false
    }

    const imagePlaceholder = this.image.firstElementChild
    this.image.replaceChild(newProfileImage(data), imagePlaceholder)
    this.name.textContent = data.name || 'Your advisor'

    this.info.append(newInfoItems(data))

    if (data.social && Object.keys(data.social).length) {
      this.info.append(newSocialIcons(data.social))
    }

    if (data.urls) {
      // todo: refine the naming her to call 'getAdvisorLinkWithCookie' the function less after additional testing
      this.link.href = getAdvisorLinkWithCookie(data.urls.site) || '/'
      if (!data.urls.site) this.link.style.pointerEvents = 'none'
      this.referral.href = getAdvisorLinkWithCookie(data.urls.referme) || (getAdvisorLinkWithCookie(data.urls.site) || '/')
    }
    this.referAdvisorConent.style.display = 'none'
    const referLink = data.type === 'team' ? getAdvisorLinkWithCookie(data.urls.site) : getAdvisorLinkWithCookie(data.urls.referme)
    if (referLink) {
      this.referAdvisorConent.style.display = 'block'
    }
    this.show()
    const e = newEvent('profile-loaded')
    e.profile = data
    this.dispatchEvent(e)
  }

  selfStart () {
    loadProfileData(this.profileID)
      .then(profile => this.renderProfile(profile))
      .catch(() => {
        if (this.finder) {
          new Promise((resolve, reject) => {
            const zip = getStorageZip() || null
            return resolve(zip)
          }).then((location) => {
            if (location) {
              let userData = getUserDataFromStorage()
              const locData = { 'postalCode': location }
              userData = { ...userData, ...{ stateProvince: { abbr: userData.state.short_name } }, ...locData }
              this.finder.setLocation(userData)
            }
          })
        }
        this.show(false)
      })
    this.hidden = false
  }

  connectedCallback () {
    if (this.isStandalone) {
      this.selfStart()
    }
  }
}

customElements.define('advisor-profile', AdvisorProfile)
