import { loadProfileData, loadGalleryData } from './utils'
import { getStorageZip, getUserDataFromStorage } from '../../services/geoCoordinatesLookup'

class DynamicAdvisorComponent extends HTMLElement {
  profile = this.querySelector('advisor-profile')
  gallery = this.querySelector('advisor-gallery')

  loadProfile () {
    if (this.profile) {
      this.profile.hidden = false
      loadProfileData(this.profile.profileID)
        .then(advisor => this.profile.renderProfile(advisor))
        .catch(() => {
          this.profile.hidden = true
          this.loadGallery()
        })
    }
  }

  loadGallery () {
    if (this.gallery) {
      this.gallery.hidden = false
      new Promise((resolve, reject) => {
        const zip = getStorageZip() || null
        return resolve(zip)
      }).then((location) => {
        if (location) {
          let userData = getUserDataFromStorage()
          const locData = { 'postalCode': location }
          userData = { ...userData, ...{ stateProvince: { abbr: userData.state.short_name } }, ...locData }
          this.gallery.setLocation(userData)
          loadGalleryData(this.gallery.zip)
            .then((profiles) => {
              this.gallery.renderAdvisorsList(profiles)
              this.gallery.show(true)
            })
            .catch(() => this.gallery.show(false))
        } else {
          this.gallery.show(false)
        }
        return location
      }).catch((error) => {
        this.gallery.show(false)
        throw new Error('Advisor query failed', error)
      })
    }
  }

  connectedCallback () {
    if (this.profile) {
      this.loadProfile()
    } else if (this.gallery) {
      this.loadGallery()
    }
  }
}

customElements.define('dynamic-advisor-component', DynamicAdvisorComponent)
