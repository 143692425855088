/* eslint-disable */

/*
 * Geolocation SDK and the Google Service details
 * Google SDK - https://maps.googleapis.com/maps/api/js?key=GOOGLE_MAPS_CLIENT_API_KEY
 */

const GEOCONSTs = {
  storage: 'prospectGeoLocDetails'
}

// Get zip code from the Storage
const getZipCodeFromStorage = () => {
  let zipCode = null
  try {
    if (typeof window.localStorage !== 'undefined') {
      const locationStorage = window.localStorage.getItem(GEOCONSTs.storage)
      if (locationStorage) {
        const parsedLocationData = JSON.parse(window.atob(locationStorage))
        if (
          parsedLocationData &&
          parsedLocationData.user &&
          parsedLocationData.user.postalCode
        ) {
          zipCode = parsedLocationData.user.postalCode.long_name
        }
      }
    }
    return zipCode
  } catch (error) {
    return zipCode
  }
}

// Get UserData from the Storage
const getUserDataFromStorage = () => {
  let returnData = null
  try {
    if (typeof window.localStorage !== 'undefined') {
      const locationStorage = window.localStorage.getItem(GEOCONSTs.storage)
      if (locationStorage) {
        const parsedLocationData = JSON.parse(window.atob(locationStorage))
        if (parsedLocationData && parsedLocationData.user) {
          returnData = parsedLocationData.user
        }
      }
      return returnData
    }
  } catch (error) {
    return returnData
  }
}

// Set User location details at the Storage
const setZipCodeAtStorage = props => {
  try {
    const prospectDetails = JSON.stringify(props)
    if (window.localStorage && typeof window.atob === 'function') {
      window.localStorage.setItem(
        GEOCONSTs.storage,
        window.btoa(prospectDetails)
      )
    }
  } catch (err) {}
  return true
}

// To check if user browser supports the native geolocation
const isGeoLocationSupported = () => {
  return navigator.geolocation
}

// Browser: To detect the user geo coordinates from the browser
const findProspectLocationFromBrowser = callback => {
  const options = {
    enableHighAccuracy: true,
    timeout: 10000 // 10 seconds
  }

  const geoLocationSuccess = position => {
    const latitude = position.coords.latitude
    const longitude = position.coords.longitude
    const address = { lat: latitude, long: longitude, props: position }
    return callback(address, 'success')
  }

  const geoLocationError = error => {
    const message = error.message
    return callback(message, 'error')
  }
  return navigator.geolocation.getCurrentPosition(
    geoLocationSuccess,
    geoLocationError,
    options
  )
}

// Filter the items(postal_code, country, city, route, street_number) from the google service
const filterItems = (userObj, diff) => {
  const returnObj = userObj.filter(item => {
    if (item.types && item.types.includes(diff)) {
      const filteredValues = {
        short_name: item['short_name'],
        long_name: item['long_name']
      }
      return filteredValues
    }
  })
  if (returnObj && returnObj.length) {
    return {
      short_name: returnObj[0].short_name,
      long_name: returnObj[0].long_name
    }
  } else {
    return { short_name: '', long_name: '' }
  }
}

// After the geo coordinates received from the Browser, following method triggers the service call to find the user location details
const getPostalAddressByLatLong = props => {
  return new Promise((resolve, reject) => {
    if (typeof google === 'undefined') {
      const returnObj = {
        status: false,
        zip: false,
        message: 'Google SDK is not available'
      }
      reject(returnObj)
    }
    const latLng = new google.maps.LatLng(props.lat, props.long)
    const geocoder = new google.maps.Geocoder()
    geocoder.geocode({ latLng: latLng }, (results, status) => {
      if (status === 'OK' && typeof results[0] !== 'undefined') {
        const prospectAddress = results[0].address_components
        const prospectFormattedAddress = results[0].formatted_address
        let postalCode = null
        if (prospectAddress && prospectAddress.length) {
          const userPostalCode = filterItems(prospectAddress, 'postal_code')
          const userCountry = filterItems(prospectAddress, 'country')
          const userState = filterItems(
            prospectAddress,
            'administrative_area_level_1'
          )
          const userCity = filterItems(prospectAddress, 'locality')
          const userStreetNumber = filterItems(prospectAddress, 'street_number')
          const userStreetRoute = filterItems(prospectAddress, 'route')

          postalCode = userPostalCode
          setZipCodeAtStorage({
            user: {
              address: prospectFormattedAddress,
              street: userStreetNumber,
              route: userStreetRoute,
              city: userCity,
              country: userCountry,
              postalCode: userPostalCode,
              state: userState
            }
          })
        }
        const returnObj = { status: true, zip: postalCode, message: status }
        resolve(returnObj)
      } else {
        const returnObj = { status: false, zip: false, message: status }
        reject(returnObj)
      }
    })
  })
}

module.exports = {
  getStorageZip: getZipCodeFromStorage,
  setZipAtStorage: setZipCodeAtStorage,
  isGeoSupported: isGeoLocationSupported,
  findGeoLocation: findProspectLocationFromBrowser,
  getZipCodeFromAddress: getPostalAddressByLatLong,
  getUserDataFromStorage: getUserDataFromStorage
}
