import { getAdvisorSearchUrl } from '../../components/Advisor/src/utils'
import { isValidZipcode } from '../../common/utils'
import { newNode } from '../DynamicAdvisorComponent/utils'
import { getAdvisorLinkWithCookie } from '.././../ui/AdvisorQueryParameter/advisors-query-param'

export const newAdvisorItem = (profile = {}) => {
  const logo = `${window.AMPCOMPATH}images/dac-missing-image.png`
  const type = 'type' in profile ? profile.type : null
  const url = 'urls' in profile ? profile.urls.site : null
  const href = getAdvisorLinkWithCookie(url) || '/'
  const image = 'image' in profile && profile.image ? profile.image : null
  const src = type === 'team' ? logo : image || logo
  const name = 'name' in profile ? profile.name : null
  const designations = 'designations' in profile ? profile.designations : null
  const title = 'title' in profile ? profile.title : null
  const target = '_blank'
  const advisor = newNode('li', { className: 'advisor u-size3of12 u-sm-sizeFull u-flex u-sm-FlexRow u-md-flexCol u-lg-flexCol u-marginBottom3gu' })
  const banner = newNode('div', { className: 'banner u-sm-sizeFull u-overflowHidden u-posRelative u-flexJustifyCenter u-flexAlignItemsCenter u-marginRight3gu' })
  const link = newNode('a', { href, target, className: 'u-posAbsolute u-sizeFull' })
  const img = newNode('img', { src, alt: name || 'Visit advisor website' })

  advisor.appendChild(banner).appendChild(link).appendChild(img)

  if (name || href || designations || title) {
    const details = newNode('div', { className: 'details Content u-sm-sizeFull u-marginVert3gu u-marginRight3gu' })
    const heading = name ? newNode('h4', { textContent: name, className: 'heading' }) : null

    if (heading) {
      details.appendChild(newNode('a', { className: 'name u-block u-marginBottom1gu', href, target })).appendChild(heading)
    }
    if (designations) {
      details.appendChild(newNode('p', { className: 'designations u-block u-marginBottom1gu', textContent: designations }))
    }
    if (title && type === 'advisor') {
      details.appendChild(newNode('p', { className: 'title u-block u-marginBottom1gu', textContent: title }))
    }
    if (href) {
      const textContent = type === 'team' ? 'Visit Team Website' : 'Visit Website'
      details.appendChild(newNode('a', { className: 'website u-block u-marginTop4gu u-marginBottom1gu', href, target, textContent }))
    }
    advisor.appendChild(details)
  }

  return advisor
}

export const newMoreAdvisorsItem = (zip = '') => {
  const target = '_blank'
  const href = isValidZipcode(zip) ? getAdvisorSearchUrl({ zip }) : '#'
  const textContent = 'View More Advisors'
  const advisors = newNode('li', { className: 'more-advisors u-size3of12 u-sm-sizeFull u-marginBottom3gu' })
  const banner = newNode('div', { className: 'banner Content u-sm-sizeFull u-overflowHidden u-posRelative u-flex u-marginRight3gu Advisor--color' })
  const link = newNode('a', { className: 'link u-posAbsolute u-sizeFull u-linkClean u-flex u-flexAlignItemsCenter', href, target })
  const text = newNode('span', { className: 'u-sizeFull u-textCenter u-textColorWhite u-textSizeMsrp1 u-textOpen u-paddingHoriz8gu u-md-paddingHoriz0 u-sm-paddingHoriz0', textContent })

  advisors.appendChild(banner).appendChild(link).appendChild(text)
  return advisors
}
