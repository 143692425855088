export function formatPhone (phone) {
  if (phone) {
    phone = phone.replace(/\(|\)| |\.|-|\//g, '')
    phone = `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`
  }

  return phone
}

export function normalizeSpaces (str) {
  return str.replace(/ {2,}/g, ' ')
}
